export default {
	SkyboxOptions: [{
		Day: [{ type: "daybox", nick: "Default Day", file: "default_day.dds" },
		{ type: "daybox", nick: "Clear Sky 01", file: "Clear_Sky_01.dds" },
		{ type: "daybox", nick: "Clear Sky 02", file: "Clear_Sky_02.dds" },
		{ type: "daybox", nick: "Overcast", file: "overcast_4k.dds" },
		{ type: "daybox", nick: "Sunset 01", file: "Sunset_01.dds" },
		{ type: "daybox", nick: "Sunset 02", file: "Sunset_02.dds" },
		{ type: "daybox", nick: "Sunset 03", file: "Sunset_03.dds" },
		{ type: "daybox", nick: "Sunset 04", file: "Sunset_04.dds" },
		{ type: "daybox", nick: "Sunset 05", file: "Sunset_05.dds" },
		{ type: "daybox", nick: "Sunset 06", file: "Sunset_06.dds" },],
		Night: [{ type: "nightbox", nick: "Default Night", file: "default_night.dds" },
		{ type: "nightbox", nick: "Clear Stars", file: "clear_stars_4k.dds" },
		{ type: "nightbox", nick: "Night Pano", file: "Night_Pano_01.dds" },],
	},
	]
}