import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
export default {
    namespaced: true,
    state() {
        return {
            user: null,
            groups: [],
            selectedRole: null,
            organisation: null,
            authState: null,
            isAdmin:false
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user != null) {
                state.groups = user.signInUserSession.idToken.payload['cognito:groups'];
                if (state.selectedRole == null) {
                    state.selectedRole = state.groups[0];
                }
                if (state.organisation == null) {
                    state.organisation = user.signInUserSession.idToken.payload['custom:organisation'];
                    if (state.organisation == "Admin") {
                        state.isAdmin = true;
                        state.organisation = state.selectedRole.replace(new RegExp('[A-Z](?!.*[A-Z].*).*$', 'gm'), "");
                    }
                }
            }
        },
        updateSelectedRole(state, role) {
            if (state.user != null) {
                if (state.groups.includes(role)) {
                    state.selectedRole = role;
                    state.organisation = state.selectedRole.replace(new RegExp('[A-Z](?!.*[A-Z].*).*$', 'gm'), "");
                }
            }
        },
        setAuthState(state, authState) {
            state.authState = authState;
        },
        USER_LOGIN(state, user) {
            state.user = user;
            state.authState = AuthState.SignedIn;
        },
        USER_LOGOUT(state) {
            state.isAdmin = false;
            state.authState = AuthState.SignedOut;;
        },
        USER_SIGNUP(state, user) {
            state.user = user;
        },
        USER_RESET(state) {
            state.user = null
            state.groups = [];
            state.selectedRole = null;
            state.organisation = null;
        }
    },
    getters: {
        profile: state => state.user,
        isSignIn: state => state.authState
    },
    actions: {
        loginUser({ commit }, user) {
            commit("USER_LOGIN", user);
        },
        async signInUser({ dispatch }) {
            await dispatch("loginUser", user);
            await dispatch("setProfile", null);
            await dispatch("notifications/subscribeNotifications", null, { root: true });
        },
        async logoutUser({ commit, dispatch }) {
            await Auth.signOut({
                global: true
            })
            commit("USER_RESET");
            dispatch("notifications/unsubscribeNotifications", null, { root: true });
            commit("USER_LOGOUT");
            location.reload();
        },
        resetState({ commit }) {
            commit("RESET_STATE");
        }
    }
}