<template>
  <div class="login">
    <div class=logo>
      <div class="lazy-background"></div>
    </div>
    <amplify-authenticator id="authenticator" loading="lazy"><amplify-sign-in slot="sign-in"
        hide-sign-up="true"></amplify-sign-in></amplify-authenticator>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

const name = "login";
onMounted(() => {
  window.localStorage.clear();
  document.getElementById("sidebar-scroll").style.display = "none";
});


</script>
<style>
.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.lazy-background {
  padding: 147px 0 0 255px;
  background: url("../assets/headless.png");
  background-repeat: no-repeat;
  background-size: 255px 147px;

}

#authenticator {
  --container-height: 25%;
  --amplify-primary-color: #48b5e7;
  --amplify-primary-tint: #48b5e7;
  --amplify-primary-shade: #48b5e7;
}
</style>
