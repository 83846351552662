import {
    getOnNotifiedSubscription
} from '../../graphql/graphqlActions'

export default {
    namespaced: true,
    state() {
        return {
            notifications: {
                all: [],
                newNotifications: 0,
                subscription: undefined
            }
        }
    },
    mutations: {
        NOTIFICATIONS_SUBSCRIBE(state, subscription) {
            state.notifications.subscription = subscription;
            state.notifications.newNotifications = 0;
        },
        NOTIFICATIONS_UNSUBSCRIBE(state) {
            let subscription = state.notifications.subscription;
            if (subscription) {
                subscription.unsubscribe();
                state.notifications.subscription = undefined;
            }
        },
        NOTIFICATIONS_NEW(state,newMessage) {
            state.notifications.all = [newMessage, ...state.notifications.all];
            state.notifications.newNotifications = state.notifications.newNotifications + 1;
        },
        NOTIFICATIONS_RESET(state) {
            state.notifications.all = []
            state.notifications.newNotifications = 0;
        }
    },
    getters: {
        newNotifications: state => state.notifications.newNotifications,
        subscription: state => state.notifications.subscription
    },
    actions: {
        async subscribeNotifications({ commit, getters, rootState }) {

            if (!rootState.user.user || rootState.notifications.notifications.subscription) return;
            
            var subscription = (eventSource) => {
                getOnNotifiedSubscription(eventSource).subscribe({
                next: async ({ value }) => {
                    const notification = value.data.onNotified;
                    commit("NOTIFICATIONS_NEW", notification);
                }},
            )};
            commit("NOTIFICATIONS_SUBSCRIBE", subscription("Error"));
            commit("NOTIFICATIONS_SUBSCRIBE", subscription("Warning"));
        },
        unsubscribeNotifications({ commit }) {
            commit("NOTIFICATIONS_UNSUBSCRIBE");
        },
        resetNotifications({ commit }) {
            commit("NOTIFICATIONS_RESET");
        }
    }
}