<template>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">Config Management</h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">Manage configurations for player names and pitch types</p>
        </div>
        <div class="demo-dropdown-wrap" style="margin-left: auto" @mouseover="checkBoxListen()"></div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div class="
                                                                                          card
                                                                                          col-span-1
                                                                                          grid grid-cols-1
                                                                                          gap-5
                                                                                          bg-white
                                                                                          dark:bg-gray-800
                                                                                          w-full
                                                                                          rounded-md
                                                                                          p-5
                                                                                          shadow
                                                                                        ">
        <div class="accordion-packed">
          <h2 v-if="!edit" style="display: flex" class="font-semibold dark:text-gray-200">
            <!-- User Info -->
            <a-input v-if="activeKey != '0'" v-model:value="selectedConfigname" placeholder="Config Save Name" />
            <a-button v-if="activeKey != '0'" type="text" @click="saveData">Save</a-button>
            <label style="
                                                                                            margin-left: auto;
                                                                                            display: flex;
                                                                                            justify-content: flex-end;
                                                                                            align-items: center;
                                                                                          " class="switch">
            </label>
          </h2>

          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="0" tab="Saved Configs">Saved Configs
              <a-list v-if="selectedConfigLocal != null" class="demo-loadmore-list" item-layout="horizontal"
                :data-source="selectedConfigLocal">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <template #actions>
                      <a @click="setConfig(item)">Load</a>
                      <a-popconfirm title=" Do you wish to delete this Config entry?" ok-text="Yes" okType= "default" cancel-text="No"
                        @confirm="deleteConfig(item)">
                        <a>Delete</a>
                      </a-popconfirm>
                      <!-- <a @click="deleteConfig(item)">Delete</a> -->
                    </template>
                    <a-list-item-meta>
                      <template #description>
                        <a>Last modified: {{ new Date(item.createdAt).toLocaleString() }} by {{ item.username }}</a>
                      </template>
                      <template #title>
                        <a>{{ item.broadcastName }}</a>
                      </template>
                      <template #avatar>
                        <ProfileOutlined />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>

            </a-tab-pane>
            <a-tab-pane key="1" tab="Pitch config">
              <a-space><select v-model="selectedPitch">
                  <option v-for="person in pitchconfig.pitches" :value="person">{{ person.Screen }}</option>
                </select>
                <a-input v-if="selectedPitch != null" v-model:value="pitchSub" placeholder="Pitch Name Substitution" />
                <a-button v-if="selectedPitch != null && pitchSub != null && pitchSub != ''" shape="circle"
                  @click="addPitchChange">
                  <template #icon>
                    <PlusOutlined />
                  </template>
                </a-button>
              </a-space>
              <a-list :grid="{ gutter: 8, column: 3 }" v-if="pitchlist != null" class="demo-loadmore-list mt-5"
                item-layout="horizontal" :data-source="pitchlist">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <template #actions>
                      <a @click="deletePitchInList(item)">delete</a>
                    </template>
                    <a-list-item-meta description="">
                      <template #title>
                        <a>Name: {{ item.Name }} </a><br>
                        <a>Screen Name: {{ item.Screen }}</a><br>
                        <a>Change: {{ item.pitchNameChange }}</a>
                      </template>
                      <template #avatar>
                        <SwapOutlined />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Player Config" force-render>
              <a-space>
                <select v-model="selectedTeam" @change="handleChange">
                  <option v-for="person in teamData" :value="person">{{ person.name }}</option>
                </select>
                <select v-if="selectedTeam != null" v-model="selectedPlayer">
                  <option v-for="person in playerData" :value="person.person">{{ person.person.fullName }}</option>
                </select>
                <a-input v-if="selectedTeam != null && selectedPlayer != null" v-model:value="userSub"
                  placeholder="Player Name Substitution" />
                <a-button v-if="selectedTeam != null && selectedPlayer != null && userSub != null && userSub != ''"
                  shape="circle" @click="addPlayerChange">
                  <template #icon>
                    <PlusOutlined />
                  </template>
                </a-button>
              </a-space>
              <br>
              <!-- <div style="height: 40px"></div> -->

              <a-list :grid="{ gutter: 8, column: 3 }" v-if="playerlist != null" class="demo-loadmore-list mt-5"
                item-layout="horizontal" :data-source="playerlist">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <template #actions>
                      <a @click="deletePlayerInList(item)">delete</a>
                    </template>
                    <a-list-item-meta description="">
                      <template #title>
                        <a>Name: {{ item.fullName }} </a><br>
                        <a>Change: {{ item.playerNameChange }}</a>
                        <br>
                        <a> Team: {{ item.team }}</a>
                      </template>
                      <template #avatar>
                        <UserOutlined />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>

            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify";
import pitchconfig from "../pitches";
import awsconfig from "../aws-exports";
import { defineComponent, onMounted, ref, watch, nextTick, reactive, computed } from "vue";
import { SmileOutlined, DownOutlined, PlusOutlined, ProfileOutlined, UserOutlined, SwapOutlined } from "@ant-design/icons-vue";
import { tSMethodSignature } from "@babel/types";
import store from "../store/index.js";
import { ConsoleLogger } from "@aws-amplify/core";
import _ from "lodash";
import { message } from 'ant-design-vue';
import jsonp from 'fetch-jsonp';
import querystring from 'querystring';
import axios from "axios";
import { Item } from "ant-design-vue/lib/menu";
import {
  getSrtConfig, getBroadcastConfig, pushBroadcast, removeBroadcast
} from "../graphql/graphqlActions";

const selectedUser = ref("");
const formState = ref({ user: {} });
const edit = ref(false);
const groups = ref([]);
var teamData = ref([]);
var playerData = ref([]);
var selectedTeam = ref(null);
var selectedPlayer = ref(null)
var selectedPitch = ref(null);
var activeKey = ref("0");
var userSub = ref(null);
var pitchSub = ref(null);
var pitchlist = ref([]);
var playerlist = ref([]);
var selectedConfigLocal = ref([]);
var selectedConfigname = ref(null);




onMounted(async () => {
  await broadcastRetrieve()
  //console.log(JSON.parse(configlist.getBroadcastConfig))
  await initSelected()
  // selectedConfigLocal.value = localStorage.getItem("LocalSettings");
  //   if (selectedConfigLocal.value == null) {
  //     let initConfigStructure = {
  //       "Saves": [
  //       ]
  //     }
  //     localStorage.setItem("LocalSettings", JSON.stringify(initConfigStructure));
  //   }
  //   selectedConfigLocal.value = JSON.parse(localStorage.getItem("LocalSettings"));
  // })
})
async function broadcastRetrieve() {
  let configlist = await getBroadcastConfig(store.state.user.organisation);
  selectedConfigLocal.value = JSON.parse(configlist.getBroadcastConfig)
  return
}

function setConfig(item) {
  selectedConfigname.value = item.broadcastName
  playerlist.value = item.config.playerChanges
  pitchlist.value = item.config.pitchChanges
  activeKey.value = "1"
  selectedPlayer.value = null;
  selectedTeam.value = null;
}

async function deleteConfig(item) {
  message.loading('Deleting Configuration');
  await removeBroadcast(item.id)
  await broadcastRetrieve()
  message.success('Successfully Deleted Configuration: ' + item.broadcastName);
}


function deletePlayerInList(item) {
  var indexOfObject = playerlist.value.findIndex(object => {
    return object.id == item.id;
  });
  playerlist.value.splice(indexOfObject, 1);
}

function deletePitchInList(item) {
  var indexOfObject = pitchlist.value.findIndex(object => {
    return object.Name == item.Name;
  });
  pitchlist.value.splice(indexOfObject, 1);
}

async function saveData() {
  if (selectedConfigname.value == null) {
    message.error('A name must be set for this configuration');
    return
  }
  var details = { "pitchChanges": pitchlist.value, "playerChanges": playerlist.value }
  await pushBroadcast(selectedConfigname.value, JSON.stringify(details), store.state.user.organisation)
  await broadcastRetrieve();
}


function addPitchChange() {

  let appendPitch = {
    ...reactive(selectedPitch.value),
    ...{ "pitchNameChange": pitchSub.value }
  }
  if (pitchlist.value.find(({ Name }) => Name == selectedPitch.value.Name)) {
    console.log("not allowed")
    message.error('This pitch name has already been edited');
    return
  };

  pitchlist.value.push(appendPitch)
  pitchSub.value = null;
  selectedPitch.value = null;

}

function addPlayerChange() {

  let appendPlayer = {
    ...reactive(selectedPlayer.value),
    ...{ "playerNameChange": userSub.value, "team": selectedTeam.value.name, "abv": selectedTeam.value.abbreviation }
  }
  if (playerlist.value.find(({ id }) => id == selectedPlayer.value.id)) {
    console.log("not allowed")
    message.error('This players name has already been edited');
    return
  };

  playerlist.value.push(appendPlayer)
  userSub.value = null;
  selectedPlayer.value = null;

}


await axios
  .get("https://statsapi.mlb.com/api/v1/teams?sportIds=1&fields=teams,abbreviation,id,name")
  .then((response) => {
    try {
      teamData.value = response.data.teams;
    } catch { }
  });
////

const handleChange = async value => {
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams/" + selectedTeam.value.id + "/roster?rosterType=fullRoster&season=2023")
    .then((response) => {
      try {
        playerData.value = response.data.roster;
      } catch { }
    });
  // { key: "lucy", label: "Lucy (101)" }
};
// testing with this method


async function initSelected() {
  var user = await store.state.user;
  return
}

async function onSubmit() {
  var difference = Object.keys(formState.value.user).filter(k => formState.value.user[k] !== selectedUser.value[k]);
  var filteredArray = difference.filter(e => e !== "role" && e !== "organisation")
  if (difference.length == 0) {
    edit.value = !edit.value;
    return
  } else if (filteredArray.length > 0) {

  }
  await initSelected()
}

async function onCancel() {
  this.edit = !this.edit;
}

function saveBroadcasterPlayerSetting(list) {
  localStorage.setItem("LocalSettings", list);
}

function saveBroadcasterPitchSetting(list) {
  localStorage.setItem("LocalSettings", list);
}

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};

</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  font-family: futura-pt, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #42586a;
}

.box {
  width: 100%;
  /* max-width: 360px; */
  padding: 15px;
}

.box h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 100%;
  letter-spacing: 1px;
  text-align: center;
}

.form {
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  margin-top: 10px;
}

.custom-radio-button__container input[type="radio"] {
  display: none;
}

.custom-radio-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 2px solid #979797; */
  border-radius: 4px;
  font-size: 600%;
  background-color: #fff;
  cursor: pointer;
}

.svg-designer {
  stroke: #979797;
}

.svg-developer {
  fill: #979797;
}

.custom-radio-button h3 {
  color: #979797;
  font-weight: 500;
  font-size: 800%;
  letter-spacing: 1px;
  text-align: center;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button {
  border: 2px solid #e94174;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.5);
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button h3 {
  color: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-designer {
  stroke: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-developer {
  fill: #e94174;
}
</style>
