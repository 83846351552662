<template>
  <Teleport to="#body-scroll">
    <div class="vld-parent">
      <!-- <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :loader="'dots'"
        :color="'#36a8dd'" /> -->
    </div>
  </Teleport>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
        User Management
      </h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">Manage users within the organisation</p>
        </div>
        <div class="demo-dropdown-wrap" style="margin-left: auto" @mouseover="checkBoxListen()"></div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div class="
          card
          col-span-1
          grid grid-cols-1
          gap-5
          bg-white
          dark:bg-gray-800
          w-full
          rounded-md
          p-5
          shadow
        ">
        <div class="accordion-packed">
          <h2 style="display: flex" class="font-semibold dark:text-gray-200">
            Organisation Management
          </h2>
          <div class="wrapper mt-5">
            <a-table :columns="columns" :data-source="users">
              <template #headerCell="{ column }">
                <template v-if="column.key === 'name'">
                  <span>
                    Name
                  </span>
                </template>
              </template>
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'name'">
                  <a>
                    {{ record.name }}
                  </a>
                </template>
                <template v-if="column.key === 'emailNotifications'">
                  <a-tag>
                    {{ record.emailNotifications }}
                  </a-tag>
                </template>
                <template v-if="column.key === 'txtNotifications'">
                  <a-tag>
                    {{ record.txtNotifications }}
                  </a-tag>
                </template>
                <template v-else-if="column.key === 'action' && record.role !== 'Admin'">
                  <span>
                    <a>Enable/Disable 一 {{ record.name }}</a>
                    <!-- <a-divider type="vertical" />
                    <a>Delete</a> -->
                    <a-divider type="vertical" />
                    <a class="ant-dropdown-link" @click="userSelected(record)">
                      Edit
                      <down-outlined />
                    </a>
                  </span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-drawer title="Edit User" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }" @close="onClose">
    <a-form :model="form"  layout="vertical">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Name" name="name">
            <a-input v-model:value="form.name" :placeholder=selectedUser.name />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="position" name="position">
            <a-input v-model:value="form.position" :placeholder=selectedUser.position />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Phone Number" name="owner">
            <a-input v-model:value="form.phoneNum" :placeholder=selectedUser.phoneNum />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Role" name="type">
            <a-select v-model:value="form.role" :placeholder=selectedUser.role>
              <a-select-option value="Viewer">Viewer</a-select-option>
              <a-select-option value="Power">Power</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Email Notifications" name="type">
            <a-checkbox v-model:checked="form.emailNotifications"></a-checkbox>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Txt Notifications" name="type">
            <a-checkbox v-model:checked="form.txtNotifications"></a-checkbox>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Allowed Instances" name="type">
            <a-select v-model:value="form.allowedInstances" :options="selectedUser.allowedInstances" mode="multiple" :size="size" placeholder="Please select"
              style="width: 100%" >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">Cancel</a-button>
        <a-button @click="onSubmit()">Submit</a-button>
      </a-space>
    </template>
  </a-drawer>

</template>
<script setup>
import { Icon } from "@iconify/vue";
import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify";
import awsconfig from "../aws-exports";
import { defineComponent, onMounted, ref, nextTick, reactive } from "vue";
import { SmileOutlined, DownOutlined } from "@ant-design/icons-vue";
import {
  getAllProfile,
  editAllProfile,
  roleAlter,
  getEC2,
} from "../graphql/graphqlActions";
// import { TreeSelect } from "";
import { tSMethodSignature } from "@babel/types";
import store from '../store/index.js'


const columns = [
  {
    name: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Screen Name",
    dataIndex: "screenName",
    key: "screenName",
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phoneNum",
    key: "phoneNum",
  },
  {
    title: "Organisation",
    dataIndex: "organisation",
    key: "organisation",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Email",
    key: "emailNotifications",
    dataIndex: "emailNotifications",
  },
  {
    title: "Txt",
    key: "txtNotifications",
    dataIndex: "txtNotifications",
  },
  {
    title: "Ec2s",
    key: "ec2s",
    dataIndex: "ec2s",
  },
  {
    title: "Action",
    key: "action",
  },
];

const selectedUser = ref("");
const users = ref(null);

const form = ref({
  name: "",
  phoneNum: "",
  role: "",
  position: "",
  emailNotifications: false,
  txtNotifications: false,
  allowedInstances: [],
});

const rules = {
  name: [
    {
      required: true,
      message: "Please enter user name",
    },
  ],
  url: [
    {
      required: true,
      message: "please enter url",
    },
  ],
  owner: [
    {
      required: true,
      message: "Please select an owner",
    },
  ],
  type: [
    {
      message: "Please choose the type",
    },
  ],
  approver: [
    {
      required: true,
      message: "Please choose the approver",
    },
  ],
  dateTime: [
    {
      required: true,
      message: "Please choose the dateTime",
      type: "object",
    },
  ],
  description: [
    {
      required: true,
      message: "Please enter url description",
    },
  ],
};

const visible = ref(false);
const showDrawer = () => {
  visible.value = true;
};
const onClose = () => {
  visible.value = false;
};
async function onSubmit() {
  let newprof = {
    name: form.value.name,
    phoneNum: form.value.phoneNum,
    position: form.value.position,
    username: selectedUser.value.Username,
    emailNotifications: form.value.emailNotifications,
    txtNotifications: form.value.txtNotifications,
    ec2s: JSON.stringify(form.value.allowedInstances),
  };
  editAllProfile(newprof);
  await roleAlter(
    selectedUser.value.Username,
    selectedUser.value.organisation,
    selectedUser.value.role,
    form.value.role
  );
  onClose();
  loadUsers();
}

async function userSelected(r) {
  selectedUser.value = r;
  await getEC2(
    "[*]",
    store.state.user.organisation
  )
    .then((response) => {
      var inst;
      var data = [];
      inst = response;
      inst.Reservations.forEach((element) => {
        element.Instances.forEach((i) => {
          data.push({ value: i.Tags.find((o) => o.Key == "Name").Value });
        });
      });
      selectedUser.value.allowedInstances = data;
    })
    .catch(function (error) {
      console.log(error);
    });
  showDrawer();
  form.value.name = selectedUser.value.name;
  form.value.emailNotifications = selectedUser.value.emailNotifications;
  form.value.phoneNum = selectedUser.value.phoneNum;
  form.value.role = selectedUser.value.role;
  form.value.position = selectedUser.value.position;
  form.value.txtNotifications = selectedUser.value.txtNotifications;
  form.value.allowedInstances = JSON.parse(selectedUser.value.ec2s); // this value needs to be a pull on profile and get the array of objects from information
}

onMounted(loadUsers);

async function loadUsers() {
  const organisation = store.state.user.organisation;
  const pre = await getAllProfile(organisation, 10, null);
  const result = JSON.parse(pre.getAllProfile);

  for (let i = 0; i < result.length; i++) {
    if (result[i].Attributes === undefined) continue;
    const object = result[i].Attributes.reduce((obj, item) => Object.assign(obj, { [item.Name]: item.Value }), {});
    result[i].email = object.email;
  }

  users.value = result;
}

</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}
</style>