<template>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">Profile</h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">Manage your user profile</p>
        </div>
        <div class="demo-dropdown-wrap" style="margin-left: auto" @mouseover="checkBoxListen()"></div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div class="
                              card
                              col-span-1
                              grid grid-cols-1
                              gap-5
                              bg-white
                              dark:bg-gray-800
                              w-full
                              rounded-md
                              p-5
                              shadow
                            ">

        <div class="accordion-packed">
          <h2 v-if="!edit" style="display: flex" class="font-semibold dark:text-gray-200">
            <!-- User Info -->
            <label style="
                                margin-left: auto;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                              " class="switch">
              <a-button @click="edit = !edit">Edit</a-button>
            </label>
          </h2>
          <h2 v-if="edit" style="display: flex" class="font-semibold dark:text-gray-200">
            Edit User Info
          </h2>
          <div class="wrapper mt-5">
            <div v-if="!edit">
              <a-form v-bind="layout" name="nest-messages" @finish="edit = !edit">
                <a-form-item label="Name">
                  <h1>{{ selectedUser.name }}</h1>
                </a-form-item>
                <a-form-item label="Phone Number">
                  <h1>{{ selectedUser.phoneNum }}</h1>
                </a-form-item>
                <a-form-item label="Position">
                  <h1>{{ selectedUser.position }}</h1>
                </a-form-item>
                <a-form-item label="Email Notifications">
                  <h1>{{ selectedUser.emailNotifications }}</h1>
                </a-form-item>
                <a-form-item label="Txt Notifications">
                  <h1>{{ selectedUser.txtNotifications }}</h1>
                </a-form-item>
                <a-form-item label="Organisation">
                  <h1>{{ selectedUser.organisation }}</h1>
                </a-form-item>
                <a-form-item label="Role">
                  <h1>{{ selectedUser.role }}</h1>
                </a-form-item>
              </a-form>
            </div>
            <div v-if="edit">
              <a-form :model="formState" v-bind="layout" name="nest-messages" @finish="edit = !edit">
                <a-form-item label="Name">
                  <a-input v-model:value="formState.user.name" :placeholder="selectedUser.name" />
                </a-form-item>
                <a-form-item label="Phone Number">
                  <a-input v-model:value="formState.user.phoneNum" :placeholder="selectedUser.phoneNum" />
                </a-form-item>
                <a-form-item label="Position">
                  <a-input v-model:value="formState.user.position" :placeholder="selectedUser.position" />
                </a-form-item>
                <a-form-item label="Email Notifications">
                  <a-checkbox v-model:checked="formState.user.emailNotifications"></a-checkbox>
                </a-form-item>
                <a-form-item label="Txt Notifications" name="type">
                  <a-checkbox v-model:checked="formState.user.txtNotifications"></a-checkbox>
                </a-form-item>
                <a-form-item label="Organisation" disabled>
                  <h1 disabled>{{ selectedUser.organisation }}</h1>
                </a-form-item>
                <a-form-item label="Role" disabled>
                  <a-select ref="select" v-model:value="formState.user.role"
                    :options="groups.map(role => ({ value: role }))" @change="handleRoleChange">
                  </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 10 }">
                  <a-button @click="onCancel()">Cancel</a-button>
                  <a-button @click="onSubmit()">Save</a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify";
import awsconfig from "../aws-exports";
import { defineComponent, onMounted, ref, nextTick, reactive } from "vue";
import { SmileOutlined, DownOutlined } from "@ant-design/icons-vue";
import { getMyProfile, getAllProfile, editMyProfile } from "../graphql/graphqlActions";
import { tSMethodSignature } from "@babel/types";
import store from "../store/index.js";
import _ from "lodash";

const selectedUser = ref("");
const formState = ref({ user: {} });
const edit = ref(false);
const groups = ref([]);

onMounted(async () => {
  await initSelected()
  formState.value.user = reactive({ ...selectedUser.value });
})

async function initSelected() {
  var user = await store.state.user;
  groups.value = user.groups;
  selectedUser.value = await getMyProfile();
  selectedUser.value.organisation = user.organisation;
  selectedUser.value.role = user.selectedRole;
  delete (selectedUser.value.ec2s)
  return
}

async function onSubmit() {
  var difference = Object.keys(formState.value.user).filter(k => formState.value.user[k] !== selectedUser.value[k]);
  var filteredArray = difference.filter(e => e !== "role" && e !== "organisation")
  if (difference.length == 0) {
    edit.value = !edit.value;
    return
  } else if (filteredArray.length > 0) {
    const { role, screenName, organisation, ...newprof } = formState.value.user;
    newprof.username = newprof.id
    delete (newprof.id)
    await editMyProfile(newprof);
  }
  setStoreRole(formState.value.user.role)
  edit.value = !edit.value;
  await initSelected()
}

async function onCancel() {
  edit.value = !edit.value;
}

function setStoreRole(role) {
  localStorage.setItem("rolePref", role);
  store.commit("user/updateSelectedRole", role);
  localStorage.setItem("Ec2Object", JSON.stringify({}));
}

const handleRoleChange = value => {
  formState.value.user.role = value;
}

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};

</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  font-family: futura-pt, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #42586a;
}

.box {
  width: 100%;
  /* max-width: 360px; */
  padding: 15px;
}

.box h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 100%;
  letter-spacing: 1px;
  text-align: center;
}

.form {
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  margin-top: 10px;
}

.custom-radio-button__container input[type="radio"] {
  display: none;
}

.custom-radio-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 2px solid #979797; */
  border-radius: 4px;
  font-size: 600%;
  background-color: #fff;
  cursor: pointer;
}

.svg-designer {
  stroke: #979797;
}

.svg-developer {
  fill: #979797;
}

.custom-radio-button h3 {
  color: #979797;
  font-weight: 500;
  font-size: 800%;
  letter-spacing: 1px;
  text-align: center;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button {
  border: 2px solid #e94174;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.5);
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button h3 {
  color: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-designer {
  stroke: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-developer {
  fill: #e94174;
}
</style>
