import { API } from 'aws-amplify'
import gql from 'graphql-tag'
//Query to get EC2 instances

const getEC2 = async (inst, organisation) => {
  const result = await API.graphql({
    query: gql`
      query getEC2($inst: String!,$organisation: String!) {
        ec2 (inst: $inst, organisation: $organisation){
    instances
  }
      }
    `,
    variables: {
      inst,
      organisation
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data

  return JSON.parse(profile.ec2.instances)
}

const getMyProfile = async () => {
  const result = await API.graphql({
    query: gql`
      query getMyProfile {
        getMyProfile {
    id
    ec2s
    name
    organisation
    phoneNum
    position
    screenName
    emailNotifications
    txtNotifications
  }
      }
    `,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getMyProfile
}

const graphEC2 = async (inst, organisation) => {
  const result = await API.graphql({
    query: gql`
      query graphEC2($inst: String!,$organisation: String!) {
        graphEC2 (inst: $inst, organisation: $organisation)
      }
    `,
    variables: {
      inst,
      organisation
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data

  return JSON.parse(profile.graphEC2)
}


const editMyProfile = async (newProfile) => {
  const result = await API.graphql({
    query: gql`
      mutation editMyProfile($newProfile: ProfileInput) {
        editMyProfile(newProfile: $newProfile){
    id
    emailNotifications
    txtNotifications
        }
}
    `,
    variables: {
      newProfile
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const ec2PushMess = async (action, command, instanceID, organisation) => {
  const result = await API.graphql({
    query: gql`
      mutation ec2pushmessages($action: String!, $command: String! , $instanceID: String!, $organisation: String!) {
        ec2pushmessages(action: $action,command: $command,instanceID: $instanceID, organisation:$organisation)
        
}
    `,
    variables: {
      action,
      command,
      instanceID,
      organisation
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const editAllProfile = async (editProfile) => {
  const result = await API.graphql({
    query: gql`
      mutation editAllProfile($editProfile: ProfileInputAll) {
        editAllProfile(editProfile: $editProfile){
          id
        }
}
    `,
    variables: {
      editProfile
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const getEvents = async (event, limit) => {
  const result = await API.graphql({
    query: gql`
      query getEvents($event: String!,$limit: Int!) {
  getAllEvents(organisation: $event, limit: $limit) {
    nextToken
    events {
      id
      createdAt
      ... on Event {
        id
        text
        userIp
        userAgent
        eventSource
        profile {
          name
          screenName
          organisation
          id
        }
      }
    }
  }
}
    `,
    variables: {
      limit,
      event
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const getAllProfile = async (organisation, limit, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getAllProfile($organisation: String!,$limit: Int, $nextToken: String) {
  getAllProfile(organisation: $organisation, limit: $limit, nextToken: $nextToken ) 
  
}
    `,
    variables: {
      limit,
      organisation,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}


const getBroadcastConfigName = async (organisation, broadcastName, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getBroadcastConfig($organisation: String!, $broadcastName: String!, $nextToken: String) {
        getBroadcastConfig(organisation: $organisation, broadcastName: $broadcastName, nextToken: $nextToken ) 
  
}
    `,
    variables: {
      organisation,
      broadcastName,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const getBroadcastConfig = async (organisation, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getBroadcastConfig($organisation: String!, $nextToken: String) {
        getBroadcastConfig(organisation: $organisation, nextToken: $nextToken ) 
  
}
    `,
    variables: {
      organisation,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const getSrtConfig = async (organisation, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getSrtConfig($organisation: String!, $nextToken: String) {
        getSrtConfig(organisation: $organisation, nextToken: $nextToken ) 
  
}
    `,
    variables: {
      organisation,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile
}

const getEventsToken = async (event, limit, nextToken) => {
  const result = await API.graphql({
    query: gql`
      query getEvents($event: String!,$limit: Int!,$nextToken: String!) {
  getAllEvents(organisation: $event, limit: $limit, nextToken: $nextToken) {
    nextToken
    events {
      id
      createdAt
      ... on Event {
        id
        text
        userIp
        userAgent
        eventSource
        profile {
          name
          screenName
          organisation
          id
        }
      }
    }
  }
}
    `,
    variables: {
      limit,
      event,
      nextToken
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const ec2Actions = async (action, instances, organisation) => {
  const result = await API.graphql({
    query: gql`
      mutation ec2Actions($action: String!,$instances: String!, $organisation: String!) {
  ec2Actions(action: $action, instances: $instances, org: $organisation)
}
    `,
    variables: {
      action,
      instances,
      organisation
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const roleAlter = async (user, organisation, role, change) => {
  const result = await API.graphql({
    query: gql`
      mutation roleAlter($user: String!,$organisation: String ,$role: String ,$change: String ) {
  roleAlter(user: $user, organisation: $organisation, role: $role, change: $change)
}
    `,
    variables: {
      user,
      organisation,
      role,
      change
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const pushBroadcast = async (broadcastName, config, organisation) => {
  const result = await API.graphql({
    query: gql`
      mutation pushbroadcast($broadcastName: String!, $config: String! ,$organisation: String!  ) {
  pushbroadcast(broadcastName: $broadcastName, config: $config, organisation: $organisation)
}
    `,
    variables: {
      broadcastName,
      organisation,
      config,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const pushSrt = async (config, organisation) => {
  const result = await API.graphql({
    query: gql`
      mutation pushsrt($config: String!,$organisation: String! ) {
  pushsrt(config: $config, organisation: $organisation)
}
    `,
    variables: {
      config,
      organisation,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const removeBroadcast = async (id) => {
  const result = await API.graphql({
    query: gql`
      mutation removebroadcast($id: ID!) {
  removebroadcast(id: $id)
}
    `,
    variables: {
      id,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  const profile = result.data
  return profile.getAllEvents
}

const getOnNotifiedSubscription = (eventSource) => {
  const onNotified = {
    query: gql`
      subscription onNotified ($eventSource: String!) {
        onNotified(eventSource: $eventSource) {
          ... on NoteEvent {
            id
            type
            text
            eventSource
            createdAt
            org
          }
        }
    }`,
    variables: {
      eventSource: eventSource
    }
  };

  return API.graphql(onNotified);
}

export {
  removeBroadcast,
  pushBroadcast,
  graphEC2,
  pushSrt,
  getBroadcastConfigName,
  getBroadcastConfig,
  getSrtConfig,
  ec2PushMess,
  editAllProfile,
  editMyProfile,
  roleAlter,
  getMyProfile,
  getAllProfile,
  getOnNotifiedSubscription,
  ec2Actions,
  getEC2,
  getEvents,
  getEventsToken
}