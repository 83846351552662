<template>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">SRT Management</h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">Manage SRT configurations </p>
        </div>
        <div class="demo-dropdown-wrap" style="margin-left: auto" @mouseover="checkBoxListen()"></div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div class="
                                                                                                        card
                                                                                                        col-span-1
                                                                                                        grid grid-cols-1
                                                                                                        gap-5
                                                                                                        bg-white
                                                                                                        dark:bg-gray-800
                                                                                                        w-full
                                                                                                        rounded-md
                                                                                                        p-5
                                                                                                        shadow
                                                                                                      ">

        <div class="accordion-packed">

          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="0" tab="SRT config">
              <a-space> <a-button @click="showModal">
                  <template #icon>
                    <PlusOutlined />
                  </template>
                </a-button>
                <a-button @click="saveSRT">
                  <template #icon>
                    <SaveOutlined />
                  </template>
                </a-button>
              </a-space>
              <a-list :grid="{ gutter: 8, column: 3 }" v-if="srtConfig != null" class="demo-loadmore-list mt-5"
                item-layout="horizontal" :data-source="srtConfig">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <template #actions>
                      <a-popconfirm title="Delete this SRT entry?" ok-text="Yes"  okType= "default" cancel-text="No"
                        @confirm="deleteSrt(item)">
                        <a>delete</a>
                      </a-popconfirm>

                    </template>
                    <a-list-item-meta description="">
                      <template #title>
                        <a>Nickname: {{ item.nick }} </a><br>
                        <!-- <a>Type: {{ item.type }}</a><br>
                        <a>Format: {{ item.format }}</a><br> -->
                        <a>Url: {{ item.url }}</a>
                      </template>
                      <template #avatar>
                        <SwapRightOutlined />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
            </a-tab-pane>
          </a-tabs>
        </div>
        <a-modal v-model:visible="visible" title="New SRT Destination" @ok="handleOk">
          <a-form :model="formState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-form-item label="Nickname">
              <a-input v-model:value="formState.nick" />
            </a-form-item>
            <!-- <a-form-item label="Type">
              SRTOut
            </a-form-item>
            <a-form-item label="Format">
              720-59p
            </a-form-item> -->
            <a-form-item label="URL">
              <a-input v-model:value="formState.url" />
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button key="back" @click="handleCancel">Cancel</a-button>
            <a-button key="submit" @click="handleOk">Confirm</a-button>
          </template>
        </a-modal>

      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify";
import pitchconfig from "../pitches";
import awsconfig from "../aws-exports";
import { defineComponent, onMounted, ref, watch, nextTick, reactive, computed } from "vue";
import { SmileOutlined, DownOutlined, PlusOutlined, SwapRightOutlined, SaveOutlined } from "@ant-design/icons-vue";
import { tSMethodSignature } from "@babel/types";
import store from "../store/index.js";
import { ConsoleLogger } from "@aws-amplify/core";
import _ from "lodash";
import { message } from 'ant-design-vue';
import jsonp from 'fetch-jsonp';
import querystring from 'querystring';
import axios from "axios";
import { Item } from "ant-design-vue/lib/menu";
import {
  getSrtConfig, pushSrt
} from "../graphql/graphqlActions";

var srtConfig = ref([]);
var visible = ref(false);
var activeKey = ref("0");
var formState = ref({
  type: 'SRTOut',
  nick: '',
  url: '',
  format: '720-59p',
});

onMounted(async () => {
  await retrieveSrt()
})

async function retrieveSrt() {
  let test = await getSrtConfig(store.state.user.organisation)
  srtConfig.value = JSON.parse(test.getSrtConfig).OutputDestinations
}

function showModal() {
  visible.value = true
}

function handleOk() {
  const found = srtConfig.value.some(el => el.nick === formState.value.nick);
  if (!found) {
    srtConfig.value.push(formState.value)
  } else {
    message.error('This nickname is not unique, please rename and resubmit');
    return
  };

  visible.value = false
  resetForm();
}

function handleCancel() {
  resetForm();
  visible.value = false;
}

function resetForm() {
  formState.value = {
    type: 'SRTOut',
    nick: '',
    url: '',
    format: '720-59p',
  }
}

async function saveSRT() {
  var details = { "OutputDestinations": srtConfig.value }
  await pushSrt(JSON.stringify(details), store.state.user.organisation)
  await retrieveSrt()
  resetForm();
}

function deleteSrt(item) {
  removeObjectWithId(item.nick)
}

function removeObjectWithId(nick) {
  let arr = srtConfig.value
  arr.splice(_.indexOf(arr, _.find(arr, function (item) { return item.nick === nick; })), 1)
}

</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  font-family: futura-pt, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #42586a;
}

.box {
  width: 100%;
  /* max-width: 360px; */
  padding: 15px;
}

.box h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 100%;
  letter-spacing: 1px;
  text-align: center;
}

.form {
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  margin-top: 10px;
}

.custom-radio-button__container input[type="radio"] {
  display: none;
}

.custom-radio-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 2px solid #979797; */
  border-radius: 4px;
  font-size: 600%;
  background-color: #fff;
  cursor: pointer;
}

.svg-designer {
  stroke: #979797;
}

.svg-developer {
  fill: #979797;
}

.custom-radio-button h3 {
  color: #979797;
  font-weight: 500;
  font-size: 800%;
  letter-spacing: 1px;
  text-align: center;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button {
  border: 2px solid #e94174;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.5);
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button h3 {
  color: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-designer {
  stroke: #e94174;
}

.custom-radio-button__container input[type="radio"]:checked+.custom-radio-button .svg-developer {
  fill: #e94174;
}
</style>
